import styled from 'styled-components';

import { ImageUrl } from '../../utils/constants';

const RegularFont = 'Rubik-Regular';

const Layout = styled.div`
  width: 100%;
  font-family: ${RegularFont};
`;

const Title = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #0D4270;
  display: inline-block;
  padding: ${({videoCSS, paddingbottom, padding, bottom, bottomrecipe}) => videoCSS ? '15px 0px 15px 0px':paddingbottom?'25px 0px 15px 0px':bottomrecipe?'0px 0px 15px 0px':bottom?"25px 0px 15px 0px":padding?'0px 0px 15px 0px':'0px 0px 0px 0px'};
  text-transform: capitalize;
  font-family: Rubik-Medium;
font-style: normal;
font-size: 18px;
line-height: 24px;
position: relative;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 235px;
  float: left;
  background-image: ${({imageSrc}) => imageSrc === 'no image' ? `url(${ImageUrl}/images/placeholder/video-placeholder.jpg)` : `url(${ImageUrl}/${imageSrc})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  &:after {
    display: none;
  }
  cursor: pointer;
`;

const Card = styled.div`
  float: left;
  margin-left: ${({videoCSS}) => !videoCSS && '0px'};
  margin-bottom: 0px;
  padding: 0px!important;
  position: relative;
  width: ${({videoCSS}) => videoCSS ? '100%' : 'calc(100% - 25px)'};
  display: flex;
  flex-wrap: wrap;
  border-left: ${({border}) => border ? border : '1px solid rgba(156, 156, 156, 0.3)'};
  border-bottom:1px solid rgba(156, 156, 156, 0.3);
  border-radius: 0 0 6px 6px;
  .text{
    margin: 14px auto; 
    font-family: Rubik-Regular;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #0D4270;
  }
  img {
    width: 100%;
    height: 235px;
    height: ${({height}) => height ? '250px':"235px"};
    float: left;
    cursor:pointer;
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    background-color: black;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    .getStarted {
      font-size: 14px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      font-family: ${RegularFont};
      width: 122px;
      height: 37px;
      background-color: rgba(190,228,200,0);
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .content {
    display: ${({recommended}) => recommended ? 'flex' : 'block'};
    align-items: center;
    float: left;
    width: 100%;
    margin:14px auto;
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgb(13, 66, 112);
    
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .workName {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
      margin-bottom: ${({recommended}) => recommended ? '0' : '0px'};
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 15px;
      align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
    >span{
      display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
    }
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #999999;
      font-family: ${RegularFont};
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .video-category {
    width: 100%;
    height: auto;
    margin-top: 25px;
    img {
      height: 49px;
      width: auto;
    }
  }
  .category-name{
    font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #0D4270;
  }
`;


const CardNew = styled.div`
  float: left;
  margin-left: ${({videoCSS}) => !videoCSS && '0px'};
  margin-bottom: 0px;
  padding: 0px!important;
  position: relative;
  width: ${({videoCSS}) => videoCSS ? '100%' : 'calc(100% - 25px)'};
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  img {
    width: 100%;
    height: 250px;
    float: left;
    cursor:pointer;
    &:hover, &:focus, &:active {
      background: rgba(0, 0, 0, 0.3);
border-radius: 6px;

    }
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 122px;
      min-height: 37px;
      background-color: #FD7175;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      color: white;
      text-align: center;
      padding: 5px 0px; 
    }
  }
  .content {
    display: ${({recommended}) => recommended ? 'flex' : 'block'};
    align-items: center;
    padding: 0px 15px;
    float: left;
    width: 100%;
    height: 70px;
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .workName {
      letter-spacing: 0px;
      color: #0D4270;
      margin-bottom: ${({recommended}) => recommended ? '0' : '5px'};
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
>span{
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #999999;
      font-family: ${RegularFont};
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .video-category {
    width: 100%;
    height: 235px;

    img {
      width: auto;
      height: 100px;
    }
  }
`;



const Border = styled.div`
 width: 100%;
 margin-top: 25px;
 border-bottom: 1px solid rgb(210,206,206);
`;

const EducateContent = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;

  .work {
    display: inline-block;
    width: 90%;
    text-align: left;
    text-align: center;
    > div {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: Rubik-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #0D4270;
    }
  }
  .svg {
    width: 10%;
    > svg {
      width: 19px;
      height: 19px;
      float: right;
    }
  }

  .workEducation {
    display: flex;
    margin: auto;
    margin-left:0px;
  }
  .webinarTitle {
    width: 100%;
  }
  .workEducationCheck {
    // min-width: 30%;
    text-align: right;
    display: ${({displayNone}) => displayNone && 'none'};
    display: flex;
    justify-content: flex-end;
  }
`;

const ChampionsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'calc(100% / 3)'};
  width: 20%
  margin-bottom: 0px;
  background-color: white;
  @media(max-width: 1200px) {
    width:  '100%';
    display: ${({grid}) => grid && 'flex'};
    justify-content: ${({grid}) => grid &&  "center"};
    align-items: ${({grid}) => grid && 'center' };
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 100%;
    width: 100%;
  }
`;

const ChampionsCardNew = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'calc(100% / 3)'};
  width: calc((100% - 50px) / 2);
  margin-bottom: 0px;
  background-color: #f3f6f9;
  position: relative;
  @media(max-width: 1200px) {
    width: ${({grid}) => grid ? '100%': 'calc(100% / 2)'};
    display: ${({grid}) => grid && 'flex'};
    justify-content: ${({grid}) => grid &&  "center"};
    align-items: ${({grid}) => grid && 'center' };
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 94%;
    width: 100%;
    margin-right: ${({margin}) => margin ? margin: '25px'};
    background: white;
  }
  >img{
    position: absolute;
    z-index: 2;
    top: 48px;
    right: 30px;
    width: 20px;
    height: 30px;
  }
`;

const ChampionsNewCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'calc(100% / 3)'};
  width: 50%
  margin-bottom: 0px;
  background-color: #f3f6f9;
  position: relative;
  @media(max-width: 1200px) {
    width: ${({grid}) => grid ? '100%': 'calc(100% / 2)'};
    display: ${({grid}) => grid && 'flex'};
    justify-content: ${({grid}) => grid &&  "center"};
    align-items: ${({grid}) => grid && 'center' };
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 94%;
    width: 100%;
    margin-right: ${({margin}) => margin ? margin: '25px'};
    background: white;
  }
  >img{
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 20px;
    width: 20px;
    height: 30px;
  }
`;

const ChampoinsCardTest = styled.div`
display: flex;
flex-wrap: wrap;
width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'calc(100% / 3)'};
width: 50%;
width: calc((100% - 50px) / 3);
width: ${({width}) => width ? width: 'calc((100% - 50px) / 3)'};
margin-right: ${({margin}) => margin ? '0px': '0px'};
margin-bottom: ${({bottom}) => bottom ? bottom: '0px'};
position: relative;
@media(max-width: 1200px) {
  width: ${({grid}) => grid ? '100%': 'calc(100% / 2)'};
  display: ${({grid}) => grid && 'flex'};
  justify-content: ${({grid}) => grid &&  "center"};
  align-items: ${({grid}) => grid && 'center' };
}
@media(max-width: 600px) {
  width: 100%;
}
> div {
  padding: 5px;
  height: 100%;
  width: 100%;
  background-color: white;
  display: block;
}
>img{
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 30px;
}
`;

const Button = styled.div`
width: 100px;
height: 35px;
margin: auto;
cursor: pointer;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #9C9C9C;
text-align: center;
padding: 10px 0;
>img{
  width: 9px;
  height: 5px;
}
`;

const ChampionsCardVideo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'calc(100% / 3)'};
  width: calc((100% - 50px) / 3);
  width: ${({width}) => width ? width: 'calc((100% - 50px) / 3)'};
  margin-right: ${({margin}) => margin ? margin: '25px'};
  margin-bottom: 0px;
  margin-bottom: ${({bottom}) => bottom ? bottom: '0px'};
  position: relative;
  @media(max-width: 1200px) {
    width: ${({grid}) => grid ? '100%': 'calc(100% / 2)'};
    display: ${({grid}) => grid && 'flex'};
    justify-content: ${({grid}) => grid &&  "center"};
    align-items: ${({grid}) => grid && 'center' };
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 100%;
    width: 100%;
    background-color: white;
  }
  >img{
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 30px;
  }
`;

const RibbbonIcon = styled.div`
width: 0px;
height: 0px;
margin: 0px;
> img{
position: relative;
z-index: 2;
top: 43px;
left: 325px;
width: 25px;
height: 30px;
}
`;

const ButtonDiv = styled.div`
width: 100%;
background: rgba(156, 156, 156, 0.1);
border-radius: 6px;
`;

const FitnessCard = styled.div`
  float: left;
  margin-left: ${({videoCSS}) => !videoCSS && '0px'};
  margin-bottom: 0px;
  padding: 0px!important;
  position: relative;
  width: ${({videoCSS}) => videoCSS ? '100%' : 'calc(100% - 25px)'};
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 0 6px 6px;
  .text{
    margin: 14px auto; 
    font-family: Rubik-Regular;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #0D4270;
  }
  img {
    width: 100%;
    height: 235px;
    height: ${({height}) => height ? '250px':"235px"};
    float: left;
    cursor:pointer;
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    background-color: black;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    .getStarted {
      font-size: 14px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      font-family: ${RegularFont};
      width: 122px;
      height: 37px;
      background-color: rgba(190,228,200,0);
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .content {
    display: ${({recommended}) => recommended ? 'flex' : 'block'};
    align-items: center;
    float: left;
    width: 100%;
    margin:14px auto;
    text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .workName {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
      margin-bottom: ${({recommended}) => recommended ? '0' : '0px'};
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      align-items: center;
      padding-left: 15px;
      align-items: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
    text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
>span{
      display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
    }
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #999999;
      font-family: ${RegularFont};
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .video-category {
    width: 100%;
    height: auto;
    margin-top: 25px;
    img {
      height: 49px;
      width: auto;
    }
  }
  .category-name{
    font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
text-align: center;
color: #0D4270;
  }
`;
export { Title, Card, CardContainer, Border, EducateContent, ImageContainer, Layout, ChampionsCard, Button, ChampionsCardNew, CardNew, ChampionsCardVideo, RibbbonIcon, ChampionsNewCard, ButtonDiv, ChampoinsCardTest, FitnessCard };
